<!-- 注册页面 -->
<template>
  <div class="register-box">
    <van-nav-bar
      title=""
      class="black_bg background"
      style="padding-top: 15px"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <van-tabs v-model="active" @change="handleTabChange">
      <van-tab title="手机号注册">
        <div class="form">
          <div class="international_code">
            <van-field
              readonly
              clickable
              is-link
              class="black_bg background white_color"
              name="picker"
              :value="value"
              placeholder="点击选择国家"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
          </div>
          <div class="phone">
            <span class="phone_number white_color"
              >+ {{ internationalCode }}</span
            >
            <van-form ref="form" validate-first>
              <van-field
                v-model="tel"
                class="black_bg background white_color"
                type="tel"
                placeholder="请输入手机号码"
              />
            </van-form>
          </div>
          <div class="verify-code">
            <van-field
              v-model="code"
              class="black_bg background"
              placeholder="请输入验证码"
            />
            <img :src="CodeImg" @click="getGraphicalCode" alt="" />
            <div class="verify-code_right none1" v-if="CodeImg == ''">
              <span
                class="white_color"
                style="font-size: 14px"
                @click="getGraphicalCode"
                >获取图形验证码</span
              >
            </div>
          </div>
          <div class="verify-code">
            <van-field
              v-model="verifyCode"
              class="black_bg background"
              maxlength="6"
              placeholder="请输入验证码"
            />
            <div class="verify-code_right">
              <span
                class="white_color"
                style="font-size: 14px"
                @click="codetxt == '获取验证码' ? getVerification() : ''"
                >{{ codetxt }}</span
              >
            </div>
          </div>
          <div class="tuijianma">
            <van-field
              v-model="recommendPhone"
              class="black_bg background"
              @input="handleChangeCode"
              placeholder="请输入推荐码"
            />
            <div class="tuijianma_right">
              <span class="white_color" style="font-size: 14px">推荐码</span
              >(选填)
            </div>
          </div>
          <div class="tuijianma">
            <!-- <van-field v-model="tuiJianMa" placeholder="请输入推荐码" /> -->
            <van-dropdown-menu active-color="#000">
              <van-dropdown-item
                v-model="tuiJianMa"
                class="black_bg background"
                :options="tuiJianMaOption"
              />
            </van-dropdown-menu>
            <div class="tuijianma_right">
              <span class="white_color" @click="vip" style="font-size: 14px"
                >会员服务机构</span
              >
            </div>
          </div>
          <div class="password">
            <van-field
              v-model="password"
              class="black_bg background"
              :type="passwordType"
              placeholder="请输入密码"
            />
            <div class="eye">
              <img
                :src="passwordType === 'password' ? closeEye : openEye"
                @click="showPwd"
              />
            </div>
          </div>
          <div class="notepass">
            <van-icon name="warning" color="#f07060" />
            <span>6-20位（支持数字、字母、或特殊符号）</span>
          </div>
          <div class="button">
            <van-button
              @click="handlePhone"
              :type="buttonType"
              size="large"
              :style="activeStyle"
              >注册</van-button
            >
          </div>
        </div>
      </van-tab>
      <van-tab title="邮箱注册">
        <div class="form">
          <div class="phone">
            <span class="phone_number emailar white_color">邮箱地址</span>
            <!-- <van-field v-model="email"  placeholder="请输入邮箱地址" /> -->
            <van-form ref="form" validate-first>
              <van-field
                v-model="email"
                class="black_bg background"
                :rules="[{ emailPattern, message: '请输入邮箱地址' }]"
                type="text"
                placeholder="请输入邮箱地址"
              />
            </van-form>
          </div>
          <div class="verify-code">
            <van-field
              v-model="emailCode"
              class="black_bg background"
              placeholder="请输入验证码"
            />
            <img :src="emailCodeImg" @click="getEmailGraphicalCode" alt="" />
            <div class="verify-code_right none1" v-if="emailCodeImg == ''">
              <span
                class="white_color"
                style="font-size: 14px"
                @click="getEmailGraphicalCode"
                >获取图形验证码</span
              >
            </div>
          </div>
          <div class="verify-code">
            <van-field
              v-model="verifyCode"
              class="black_bg background"
              placeholder="请输入验证码"
            />
            <div class="verify-code_right">
              <span
                class="white_color"
                style="font-size: 14px"
                @click="codetxt1 == '获取验证码' ? getEmailVerification() : ''"
                >{{ codetxt1 }}</span
              >
            </div>
          </div>
          <!-- <div class="tuijianma">
            <van-field v-model="tuiJianMayou" placeholder="请输入推荐码" />
            <div class="tuijianma_right">
              <span style="color: #000">推荐码</span>(选填)
            </div>
          </div> -->
          <div class="tuijianma">
            <van-field
              v-model="tuiJianMayou"
              class="black_bg background"
              @input="handleChangeCode"
              placeholder="请输入推荐码"
            />
            <div class="tuijianma_right">
              <span class="white_color" style="font-size: 14px">推荐码</span
              >(选填)
            </div>
          </div>
          <div class="tuijianma">
            <!-- <van-field v-model="tuiJianMa" placeholder="请输入推荐码" /> -->
            <van-dropdown-menu active-color="#000">
              <van-dropdown-item
                v-model="emailMechanism"
                :options="tuiJianMaOption1"
              />
            </van-dropdown-menu>
            <div class="tuijianma_right">
              <span class="white_color" style="font-size: 14px"
                >会员服务机构</span
              >
            </div>
          </div>
          <div class="password">
            <van-field
              v-model="telyou"
              class="black_bg background"
              :type="passwordType"
              placeholder="请输入密码"
            />
            <div class="eye">
              <img
                :src="passwordType === 'password' ? closeEye : openEye"
                @click="showPwd"
              />
            </div>
          </div>
          <div class="notepass">
            <van-icon name="warning" color="#f07060" />
            <span>6-20位（支持数字、字母）</span>
          </div>
          <div class="button button_blue">
            <van-button
              :type="buttonType"
              class="white_color"
              style=""
              @click="emailSubmit"
              size="large"
              >注册</van-button
            >
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="arrengt">
      <input type="checkbox" v-model="checked" />
      注册即代表您同意<a style="color: #fff" @click="authorization"
        >《用户协议》</a
      >及<a @click="Privacy" style="color: #fff">《隐私政策》</a>
    </div>
    <!-- <router-link to='/login' style="color:#0754D3;font-size:16px;margin-left:5%;margin-top:3%;">←去登录</router-link> -->

    <div class="selectPayType" v-if="isShowSign">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="content">请下载APP，完成实名认证后可获得奖励</div>
        <div class="btnGroup">
          <div class="unCompleted" @click="isShowSign = false">取消</div>
          <div class="completed" @click="openApp">去下载app</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import closeEye from '@/assets/images/close_eye.png'; //闭眼
import openEye from '@/assets/images/open_eye.png'; //睁眼
export default {
  data() {
    return {
      checked: true,
      isShowSign: false,
      active: 0,
      value: '中国 (大陆)',
      columns: ['中国 (大陆)'],
      showPicker: false,
      internationalCode: '86',
      verifyCode: '',
      tuiJianMa: '',
      tuiJianMayou: '',
      closeEye: closeEye,
      openEye: openEye,
      passwordType: 'password',
      buttonType: 'default',
      tel: '',
      email: '',
      password: '',
      telyou: '',
      recommendPhone: '', // 推荐码
      recommendPhoneYesOrNo: true,
      emailMechanism: '', //机构id
      code: '', // 图形验证码
      emailCode: '', // 图片验证码  邮箱
      tuiJianMaOption: [],
      CodeImg: '', // 图片验证码
      emailCodeImg: '', // 图片验证码
      pattern: /^1[3-9]\d{9}$/, // 正则验证手机号
      emailPattern:
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, //正则验证邮箱
      // 手机号注册按钮颜色
      activeStyle: {
        color: '#666666',
        background: 'bule',
      },
      timeID: null,
      tuiJianMaOption1: [],
      codetxt: '获取验证码',
      timer: null,
      codetxt1: '获取验证码',
      timer1: null,
      emailExp: new RegExp(
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      ),
    };
  },
  created() {},
  components: {},

  mounted() {
    if (sessionStorage.getItem('regcode')) {
      this.recommendPhone = sessionStorage.getItem('regcode');
      this.tuiJianMayou = sessionStorage.getItem('regcode');
    }
    if (this.$route.query.code) {
      sessionStorage.setItem('regcode', this.$route.query.code);
      this.recommendPhone = this.$route.query.code;
      this.tuiJianMayou = this.$route.query.code;
    }
    this.handleAgentInfo();
  },

  computed: {
    //监听注册按钮变蓝
    changeData() {
      const { password } = this.password;
      //			return password;
      // 如果值比较多
      return { password };
    },
  },
  watch: {
    //监听注册按钮变蓝
    // changeData: {
    //   handler(newValue, oldValue) {
    //     if (newValue.password) {
    //       this.activeStyle.color = "#000";
    //       this.activeStyle.background = "rgb(221, 221, 221)";
    //     } else {
    //       this.activeStyle.color = "#fff";
    //       this.activeStyle.background = "#0754D3";
    //     }
    //   },
    // },
  },

  methods: {
    openApp() {
      window.open('https://v.hxnft.shop/appDownload/index.html');
      this.isShowSign = false;
    },
    // 用户协议
    authorization() {
      this.$router.push('/authorization');
    },
    Privacy() {
      this.$router.push('/Privacy');
    },
    vip() {},

    // 推荐码监听
    handleChangeCode(e) {
      clearTimeout(this.timeID);
      this.timeID = setTimeout(() => {
        if (this.active == 0) {
          this.recommendPhone = e;
        } else {
          this.tuiJianMayou = e;
        }
        this.handleAgentInfo();
      }, 1000);
    },

    handleTabChange() {
      this.handleAgentInfo();
    },
    handleAgentInfo() {
      this.$api
        .getUserAgentInfo({
          referralCode:
            this.active == 0 ? this.recommendPhone : this.tuiJianMayou,
        })
        .then((res) => {
          if (res.code == 0) {
            if (this.active == 0) {
              let date = [];
              res.data.map((item) => {
                date.push({ text: item.groupCode, value: item.id });
              });
              this.tuiJianMaOption = date;
              this.tuiJianMa = res.data[0].id;
            } else {
              let date = [];
              res.data.map((item) => {
                date.push({ text: item.groupCode, value: item.id });
              });
              this.tuiJianMaOption1 = date;
              this.emailMechanism = res.data[0].id;
            }
          }
        });
    },
    validator(val) {
      return /1\d{10}/.test(val);
    },
    // asyncValidator(val) {
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       Toast.clear();
    //       resolve(/\d{6}/.test(val));
    //     }, 1000);
    //   });
    // },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    },
    onClickLeft() {
      this.$router.back();
    },
    // 获取验证码
    getVerification() {
      if (!this.tel) {
        this.$toast('请输入手机号');
        return;
      }
      if (!this.code) {
        this.$toast('请输入图形验证码');
        return;
      }
      let toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        tel: this.tel,
        code: this.code,
      };

      this.$api.getVerification(params).then((res) => {
        if (res.code == 0) {
          // 调用记载中提示
          toast.clear();
          // this.$toast.success('验证码已发送')
          Toast.success({
            message: '验证码已发送',
            forbidClick: true,
          });
          let n = 60;
          this.timer = setInterval(() => {
            this.codetxt = `${n--}s`;
            if (n == 0) {
              this.codetxt = '获取验证码';
              clearInterval(this.timer);
            }
          }, 1000);
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        } else {
          this.$toast.fail('获取失败');
        }
      });
    },
    // 获取图片验证码
    getGraphicalCode() {
      if (!/^[1][0-9]{10}$/.test(this.tel))
        return this.$toast('手机号格式不正确');
      let params = {
        tel: this.tel,
      };
      this.$refs.form
        .validate()
        .then(() => {
          this.$toast.success('获取成功');
          this.$api.getGraphicalCode(params).then((res) => {
            this.CodeImg = (URL || webkitURL).createObjectURL(
              new Blob([res], { type: 'img/jpeg' }),
            );
          });
        })
        .catch(() => {
          this.$toast.fail('获取失败');
        });
    },
    // 手机号注册
    handlePhone() {
      if (!this.checked == true) {
        this.$toast('请先同意用户协议与隐私政策');
        return;
      }
      if (!this.tel) {
        this.$toast('请输入手机号码');
        return;
      }
      if (!this.verifyCode) {
        this.$toast('请输入验证码');
        return;
      }
      if (!this.password) {
        this.$toast('请输入密码');
        return;
      }
      var reg = /^[a-zA-Z0-9]{6,20}$/;
      if (reg.test(this.password) == true) {
        let params = {
          tel: this.tel,
          checkCode: this.verifyCode,
          pwd: this.password,
          userAgentinfoid: this.tuiJianMa, // 机构id
          referralCode: this.recommendPhone, // 推荐码
        };
        this.$api.anyRegister(params).then((res) => {
          if (res.code == 0) {
            if (this.$route.query.code) {
              this.isShowSign = true;
              return;
            }
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('nickName', res.data.nickName);

            localStorage.setItem('proportionNum', res.data.proportionNum);
            this.$toast.success(res.msg);
            this.$router.push('/home');
            localStorage.setItem('proportionNum', res.data.proportionNum);
            // if (this.$route.fullPath.indexOf('redirect')) {
            //   const path = this.$route.fullPath.split('redirect=');
            //   if (path.length > 1) {
            //     this.$router.push(decodeURIComponent(path[1]));
            //   } else {
            //     this.$router.push('/home');
            //   }
            // } else {
            //   this.$router.push('/home');
            // }
          } else {
            this.$toast(res.msg);
          }
        });
      } else {
        this.$toast('6-20位（支持数字、字母、或特殊符号）');
      }
    },

    // 获取邮箱验证码
    getEmailVerification() {
      let params = {
        email: this.email,
        code: this.emailCode,
      };
      let toastEml = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.$api.getEmailVerification(params).then((res) => {
        if (res.code == 0) {
          toastEml.clear();
          this.$toast.success('验证码已发送');
          let n = 60;
          this.timer1 = setInterval(() => {
            this.codetxt1 = `${n--}s`;
            if (n == 0) {
              this.codetxt1 = '获取验证码';
              clearInterval(this.timer1);
            }
          }, 1000);
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        } else {
          this.$toast.fail('获取失败');
        }
      });
    },
    // 获取邮箱图片验证码
    getEmailGraphicalCode() {
      if (!this.emailPattern.test(this.email)) {
        return this.$toast('非法邮箱格式');
      }
      let params = {
        email: this.email,
      };
      // this.$refs.form.validate().then(() => {
      this.$api.getEmailGraphicalCode(params).then((res) => {
        if (res.code == 1) {
          this.$toast.fail('非法邮箱格式');
        } else {
          this.emailCodeImg = (URL || webkitURL).createObjectURL(
            new Blob([res], { type: 'img/jpeg' }),
          );
        }
      });
      // }).catch(() => {
      //   this.$toast.fail('获取失败')
      // })
    },
    // 邮箱注册
    emailSubmit() {
      if (!this.email) {
        this.$toast('请输入邮箱地址');
        return;
      }
      if (!this.verifyCode) {
        this.$toast('请输入验证码');
        return;
      }
      if (!this.telyou) {
        this.$toast('请输入密码');
        return;
      }
      let params = {
        email: this.email,
        pwd: this.telyou,
        checkCode: this.verifyCode,
        referralCode: this.tuiJianMayou, // 推荐码
        userAgentinfoid: this.emailMechanism, // 机构id
      };
      this.$api.emailSubmit(params).then((res) => {
        if (res.code == 0) {
          if (this.$route.query.code) {
            this.isShowSign = true;
            return;
          }
          Toast.success({
            message: res.msg,
            forbidClick: true,
          });
          this.$router.push('/home');
          // if (this.$route.fullPath.indexOf('redirect')) {
          //   const path = this.$route.fullPath.split('redirect=');
          //   if (path.length > 1) {
          //     this.$router.push(decodeURIComponent(path[1]));
          //   } else {
          //     this.$router.push('/home');
          //   }
          // } else {
          //   this.$router.push('/home');
          // }
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.van-checkbox__icon--checked {
  // margin-top: 20px;
  position: absolute;
  top: 0px;
  left: 20px;
}
.register-box {
  min-height: 100vh;
  display: flex;
  background-color: black;
  flex-direction: column;
  width: 100%;
}
.white_width {
  width: 200px;
}
/deep/.van-nav-bar__arrow {
  color: #fff;
  font-size: 24px;
}
/deep/.van-dropdown-menu__title {
  color: #fff;
}
/deep/.van-tab {
  color: #d9d9d9;
  font-size: 14px;
  line-height: 26px;
}

/deep/.van-tab--active {
  color: #fff;
  font-weight: bold;
}

/deep/.van-tabs__line {
  width: 50%;
  border-radius: 0;
  height: 1px;
  background-color: #fff;
}
/deep/.van-tabs__nav--line {
  background-color: #000;
}
/deep/.van-tabs__wrap {
  margin: 0 22px;
}
/deep/.van-tabs--line .van-tabs__wrap {
  border-bottom: 1px solid rgba(37, 37, 37, 1);
}
.van-button--large {
  height: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  font-size: 16px;
  color: #c2c2c2;

  /deep/ .van-field__control {
    color: #fff !important;
  }
  .van-cell {
    padding: 10px 0;
  }

  .phone {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #252525;
    padding: 5px 0;

    .phone_number {
      display: flex;
      width: 16%;
      padding: 0 10px 0 0px;
      color: #eee;
    }

    .phone_number::after {
      content: ' ';
      display: flex;
      width: 1px;
      height: 13px;
      margin-left: 10px;
      margin-top: 6px;
      background: rgba(218, 218, 218, 1);
    }

    .emailar {
      width: 40%;
    }
  }

  .verify-code {
    display: flex;
    border-bottom: 1px solid #252525;
    align-items: center;
    padding: 5px 0;

    .verify-code_right {
      display: flex;
      width: 48%;
      align-items: center;
      font-size: 15px;

      span {
        display: flex;
        align-items: center;
      }

      span::before {
        content: ' ';
        display: flex;
        width: 1px;
        height: 19px;
        margin-right: 10px;
        background: rgba(218, 218, 218, 1);
      }
    }
    .none1 {
      span::before {
        content: ' ';
        display: flex;
        width: 0px;
        height: 0px;
        margin-right: 10px;
        background: rgba(218, 218, 218, 1);
      }
    }
  }

  .tuijianma {
    display: flex;
    border-bottom: 1px solid #252525;
    align-items: center;
    padding: 5px 0;

    .tuijianma_right {
      display: flex;
      width: 48%;
      align-items: center;
      justify-content: space-between;

      span {
        display: flex;
        align-items: center;
      }

      span::before {
        content: ' ';
        display: flex;
        width: 1px;
        height: 19px;
        margin-right: 10px;
        background: rgba(218, 218, 218, 1);
      }
    }
  }

  .password {
    display: flex;
    border-bottom: 1px solid #252525;
    align-items: center;
    padding: 5px 0;

    span {
      display: flex;
      width: 38%;
      align-items: center;
    }

    span::before {
      content: ' ';
      display: flex;
      width: 1px;
      height: 30px;
      margin-right: 10px;
      background: #eeeeee;
    }

    .eye {
      display: flex;

      img {
        width: 17px;
        height: auto;
        padding: 0 20px;
      }
    }
  }

  .button {
    margin-top: 30px;

    .van-button--primary {
      color: #fff;
      background: #0754d3;
      border: 1px solid #0754d3;
    }

    .van-button--default {
      color: #fff !important;
      // background-color: rgba(51, 51, 51, 1) !important;
      // 邮箱注册按钮颜色
      background-color: #0754d3;
      border: 0;
    }
  }

  .van-button--default {
    background: #0754d3;
    // background-color: blue;
  }
}

.arrengt {
  font-size: 13px;
  color: #747474;
  text-align: center;
  margin-top: 20px;
  position: relative;

  a {
    color: #181818;
  }
}

.notepass {
  font-size: 12px;
  margin-top: 10px;
  color: #f07060;

  span {
    margin-left: 6px;
  }
}

.van-dropdown-menu {
  width: 100%;
}

/deep/ .van-dropdown-menu__title {
  position: unset !important;
}

/deep/.van-dropdown-menu__title::after {
  right: 10px !important;
}

/deep/.van-dropdown-menu__item {
  // 下拉菜单
  justify-content: space-between;
  width: 200px;
}

/deep/.van-dropdown-menu__bar {
  background-color: unset;
}
/deep/.van-cell__title {
  // height: px;
  line-height: 24px;
}

/deep/.van-cell {
  padding: 8px 10px 0px 10px;
}
/deep/.van-cell__title,
.van-cell__value {
  -webkit-flex-: auto;
  flex: 8;
  // line-height: 2;
}

.background {
  background-color: black;
}
.selectPayType {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  .selectPayTypeBox {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .bg {
    width: 80%;
    // min-height: 160px;
    padding: 30px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .content {
      font-size: 16px;
      color: #666666;
      margin-bottom: 25px;
      text-align: center;
    }
    .btn {
      width: 60%;
      height: 35px;
      margin: 0 auto;
      background: #0754d3;
      border-radius: 3px;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      div {
        background: #0754d3;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        width: 90px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        &.unCompleted {
          border: 1px solid #0754d3;
          background: transparent;
          color: #0754d3;
        }
      }
    }
  }
}
</style>
